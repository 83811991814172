import * as React from 'react';

import { Card } from '@axiom-ui/react/card';
import { cn } from '@axiom-ui/react/lib';

/**
 * PageFeedback is a component that is used to display a full page feedback to the user.
 *
 * @example
 * <PageFeedback>
 *   <PageFeedback.Info>
 *     <PageFeedback.Body></PageFeedback.Body>
 *     <PageFeedback.Actions></PageFeedback.Actions>
 *   </PageFeedback.Message>
 * </PageFeedback>
 */

const PageFeedbackRoot = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex size-full flex-col items-center justify-center gap-10 bg-canvas-2',
      className,
    )}
    {...props}
  >
    {children}
  </div>
));
PageFeedbackRoot.displayName = 'PageFeedback';

const PageFeedbackHeader = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, children, ...props }, ref) => (
  <div className={cn('flex flex-col gap-4', className)} {...props}>
    {children}
  </div>
));
PageFeedbackHeader.displayName = 'PageFeedback.Header';

const PageFeedbackInfo = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, children, ...props }, ref) => (
  <Card
    ref={ref}
    className={cn('flex flex-col gap-10 p-5 text-center', className)}
    {...props}
  >
    {children}
  </Card>
));
PageFeedbackInfo.displayName = 'PageFeedback.Info';

const PageFeedbackBody = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('flex max-w-2xl flex-col gap-4', className)}
    {...props}
  >
    {children}
  </div>
));
PageFeedbackBody.displayName = 'PageFeedback.Body';

const PageFeedbackActions = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'flex w-full flex-col-reverse justify-center gap-2 sm:flex-row sm:gap-4',
      className,
    )}
    {...props}
  >
    {children}
  </div>
));
PageFeedbackActions.displayName = 'PageFeedback.Actions';

export const PageFeedback = Object.assign(PageFeedbackRoot, {
  Header: PageFeedbackHeader,
  Info: PageFeedbackInfo,
  Body: PageFeedbackBody,
  Actions: PageFeedbackActions,
});
